<template>
  <div class="register bg-img">
    <breadcrumb style="position:static;"></breadcrumb>
    <div class="container">
      <div id="register-row" class="row justify-content-center align-items-center">
        <div id="register-column" class="col-lg-6 col-md-6 col-sm-8 col-12">
          <div id="register-box" class="col-12">
            <form>

              <div class="register-info text-center">
                <h3 class="text-center text-info fw700">Зареєструватися</h3>
                <p>Створіть профіль швидко і просто зараз.</p>
              </div>

              <div class="form-group">
                <div class="inner-addon left-addon">
                  <i class="glyphicon material-icons icon" aria-hidden="true" data-wg-notranslate="">person</i>
                  <input type="text" class="form-control form-control-rtl" @blur="validateNameInput" v-model="credentials.name" placeholder="Ім'я та прізвище">
                  <span class="validation-error" v-show="showNameError">Введіть своє ім'я та прізвище.</span>
                  <span class="validation-error" v-show="showNameFormatError">Будь ласка, не використовуйте цифри або спеціальні символи.</span>
                </div>
              </div>

              <div class="form-group">
                <div class="inner-addon left-addon">
                  <i class="glyphicon material-icons icon" aria-hidden="true" data-wg-notranslate="">email</i>
                  <input type="text" class="form-control form-control-rtl" @blur="validateEmailInput(), checkIfExists()" v-model="credentials.email" placeholder="e-mail">
                  <span class="validation-error" v-show="showEmailExistsError">З такою адресою електронної пошти вже існує обліковий запис.</span>
                  <span class="validation-error" v-show="showEmailError">Неправильний формат електронної пошти.</span>
                  <span class="validation-error" v-show="showEmptyEmailError">Введіть свою електронну адресу</span>
                </div>
              </div>

              <div class="form-group mb-2">
                <div class="inner-addon left-addon">
                  <i class="glyphicon material-icons icon" aria-hidden="true" data-wg-notranslate="">lock</i>
                  <input :type="pwdType" class="form-control form-control-rtl" @blur="validatePasswordInput" v-model="credentials.password" placeholder="пароль">
                  <span @click="showPwd" class="material-icons ml-auto show-pwd" data-wg-notranslate="">{{pwdIcon}}</span> 
                  <span class="validation-error" v-show="showPasswordError">Введіть пароль. Будь ласка, зверніть увагу на вимоги, наведені нижче.</span>
                </div>
              </div>

              <div class="mb-2 pw-req">Щонайменше 8 символів, 1 велика літера, 1 мала літера, 1 цифра</div>

              <div class="form-group">
                <div class="inner-addon left-addon">
                  <i class="glyphicon material-icons icon" aria-hidden="true" data-wg-notranslate="">lock</i>
                  <input :type="pwdRepeatType" class="form-control form-control-rtl" @blur="validatePasswordRepeatInput" v-model="credentials.passwordRepeat" placeholder="Повторити пароль">
                  <span @click="showRepeatPwd" class="material-icons ml-auto show-pwd" data-wg-notranslate="">{{pwdRepeatIcon}}</span> 
                  <span class="validation-error" v-show="showPasswordRepeatError">Паролі не збігаються.</span>
                </div>
              </div>


              <div class="form-group">
                <div class="checkbox">
                  <input class="input-style form-check-input" type="checkbox" tabindex="0" v-model="credentials.agbAccepted" id="dataSecurity" @change="checkBoxChanged">
                  <label class="form-check-label" for="dataSecurity">Прийняти політику конфіденційності</label>
                  <span class="validation-error" v-show="showDataSecError">Ви повинні прийняти політику конфіденційності.</span>
                </div>
              </div>

              <div class="form-group justify-content-center">
                <button type="submit" @click="handleRegister" style="width: 50%;" class="btn btn-hover btn-fill btn-focus-design register-btn">Зареєструватися</button>
              </div>

              <div class="register-row justify-content-center">
                <div class="text-center mb-2">Вже в справі?</div>
                <router-link class="btn login-btn mb-3" style="width: 50%;" :to="{ name: 'Login'}">
                  Увійдіть в систему
                </router-link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { csrf, emailExists } from '@/api/auth';
  import { validEmail, validatePassword } from '@/utils/validate';
  import { handleCollectionInvitation } from '@/api/collection';
  import { showLoader, hideLoader } from '@/utils/helpers';
  import Resource from '@/api/resource';
  const userResource = new Resource('users');

  export default {
    name: 'Register',
    components: {
      Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
    },
    data() {
      return {
       credentials: {
        name: "",
        email: "",
        password: "",
        passwordRepeat: "",
        agbAccepted: false,
        appId: this.$appId,
        sharekey: null,
        appUrl: window.location.origin,
      },
      showEmailExistsError: false,
      showNameError: false,
      showNameFormatError: false,
      showEmailError: false,
      showEmptyEmailError: false,
      showPasswordError: false,
      showPasswordRepeatError: false,
      showDataSecError: false,
      loader: false,
      pwdType: 'password',
      pwdIcon: 'visibility',
      pwdRepeatType: 'password',
      pwdRepeatIcon: 'visibility',
    };
  },
  beforeCreate() {
    /*Collection Invitation ...*/
    if(this.$route.query.sharekey){

      handleCollectionInvitation(this.$route.query.sharekey).then( response => {
        /*save the institution*/
        this.credentials.sharekey = response.data;
        this.$notify({
          duration: 4500,
          title: "Einladung annehmen",
          text: "Registriere dich, um die Einladung zu einer Merkliste anzunehmen."
        });

      });
    }
  },
  methods: { 
    showLoader,
    hideLoader,
    validEmail,
    validatePassword,
    handleCollectionInvitation,
    showPwd() {
      if (this.pwdType === 'password') {
        this.pwdIcon = 'visibility_off';
        this.pwdType = '';
      } else {
        this.pwdType = 'password';     
        this.pwdIcon = 'visibility';
      }
    },
    showRepeatPwd(){
      if (this.pwdRepeatType === 'password') {
        this.pwdRepeatIcon = 'visibility_off';
        this.pwdRepeatType = '';
      } else {
        this.pwdRepeatType = 'password';     
        this.pwdRepeatIcon = 'visibility';
      }
    },
    checkBoxChanged(origin){
      this.$emit('checkBoxChanged', this.checked);
    },
    validateNameInput(){
      var letters = /^[a-zA-ZÀ-ž\u0621-\u064A ]+$/;
      if(this.credentials.name == "" || this.credentials.name.length<4){
        this.showNameError = true;
        this.showNameFormatError = false;
        return false;
      }
      if(!letters.test(this.credentials.name)) {
        this.showNameFormatError = true;
        this.showNameError = false;
        return false;
      }
      this.showNameError = false;
      this.showNameFormatError = false;
      return true;
    },
    validatePasswordInput(){
      if(!this.validatePassword(this.credentials.password)){
        this.showPasswordError=true;
        return false;
      }else{
        this.showPasswordError=false;
        return true;
      }
    },
    validatePasswordRepeatInput(){
      if(this.credentials.password != this.credentials.passwordRepeat){
        this.showPasswordRepeatError=true;
        return false;
      }else{
        this.showPasswordRepeatError=false;
        return true;
      }
    },
    validateEmailInput(){
      this.credentials.email = this.credentials.email.replace(/\s+/g, '');
      if(!validEmail(this.credentials.email)){
        if(this.credentials.email !== ""){
          this.showEmailError=true;
          this.showEmptyEmailError=false;
        }
        else{
          this.showEmptyEmailError=true;
          this.showEmailError=false;
        }
        return false;
      }
      else{
        this.showEmailError=false;
        this.showEmptyEmailError=false;
        return true;
      }
    },
    validateDataSecAccepted(){
      if(!this.credentials.agbAccepted){
        this.showDataSecError=true;
        return false;
      }
      else{
        this.showDataSecError=false;
        return true;
      }
    },
    checkIfExists(){
      var theMail = {email: this.credentials.email};
      emailExists(theMail).then(response => {
        if(response.status == "204"){
          /*no user*/
          this.showEmailExistsError = false;
        }
        else if(response.status == "200"){
          /*a user exists*/
          this.showEmailExistsError = true;
        }
      });
    },
    handleRegister(e) {
      e.preventDefault();
      var nameValid = this.validateNameInput();
      var emailValid = this.validateEmailInput();
      var passwordValid = this.validatePasswordInput();
      var passwordRepeatValid = this.validatePasswordRepeatInput();
      var dataSecurityAccepted = this.validateDataSecAccepted();
      if(!nameValid || !emailValid || !passwordValid || !passwordRepeatValid || this.showEmailExistsError || !dataSecurityAccepted){
        return;
      }

      this.loader = this.showLoader(this.loader);

      csrf().then(() => {
        userResource.store(this.credentials).then(() => {

          this.$notify({
            duration: 6000,
            title: "Registrierung erfolgreich!",
            text: "Deine Registrierung in dem Kiel.RefugeeCompass war erfolgreich. Bitte verifiziere deine E-Mail Adresse."
          });

          this.$router.push({name:'Login', query: { afterRegistration: true }});

        })
        .catch(() => {
         this.$notify({
          type: 'error',
          duration: 5000,
          title: "Registrierung fehlgeschlagen!",
          text: "Die Registrierung ist fehlgeschlagen. Bitte probiere es zu einem späteren Zeitpunkt noch einmal!"
        });
       }).finally(()=>{
         this.loader = this.hideLoader(this.loader);
       });
     });
    },
  }
}
</script>

<style lang="scss" scoped="">
  @import '@/scss/_variables.scss';

  .validation-error{
    color:#E5321B;
    font-size: 13px;
    text-align: center;
  }

  .register {
    margin-bottom: 62px;
    position: relative;
    background: #fff;
    padding-top: 30px;

    @media(min-width:992px){
      height: 100vh;
      margin-bottom: 256px;
    }

    @media(max-width: 991px) {
      padding-top: 0px;
    }

    .anbieter-notice{
      margin-top:30px; 
      text-align:center;

      a{
        color:#2E55A5;

        &:hover{
          color:#0d6efd;
        }
      }
    }

    .container {
      max-width: 1000px;

      #register-column{

        @media (max-width: 575px) {
          padding: 0px 50px;
        }

        @media (max-width: 400px) {
          padding: 0px 30px;
        }

      }


      h3.text-info {
        color: #00183E !important;
        margin-bottom: 15px;
        font-size: 36px;
      }

      .register-info {
        margin-bottom: 15px;
      }

      .form-check{
        margin-bottom: 20px;      
      }

      .form-group {
        margin-bottom: 30px;

        @media (max-width: 575px) {
          margin-bottom: 20px;
        }

        .left-addon .glyphicon {
          left: 0px;
        }

        .inner-addon {
          position: relative;
          width: 100%;
        }

        .inner-addon .glyphicon {
          position: absolute;
          padding: 9px;
          pointer-events: none;
          color: #157FAC;
        }

        .left-addon input {
          padding-left: 40px;
        }
      }
    }
  }
  .checkbox{
    width: 100%;

    label {
      position: relative;
      text-align: left;
      cursor: pointer;
      width: 100%;

      &:before{
        content: '';
        position: absolute;
        left: -40px;
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(58, 58, 58, 0.2);
        display: inline-flex;
        margin-right: 20px;
      }

      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 9px;
        width: 6px;
        height: 14px;
        border: solid #FFFFFF;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        left: -33px;
      }

    }

    input[type="checkbox"]{
      opacity: 0;

      &:focus-visible + label::before {
        border: 2px dashed #E30059 !important;
        border-radius: 3px !important;
        outline: none !important;
      }

      + label::after {
        content: none;
      }

      &:checked{ 

        + label::before {
          background-color: #FFF;
        }

        + label::after{
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          left: 9px;
          width: 6px;
          height: 14px;
          border: solid #2E55A5;
          border-top-width: medium;
          border-right-width: medium;
          border-bottom-width: medium;
          border-left-width: medium;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          left: -33px;
        }
      }
    }
  }

  .register-row {
    text-align: center;
  }

  .login-btn {
    color: #2E55A5;
    background-color: #FFF;
    border: 2px solid #157FAC;

    &:hover {
      color: #FFF;
      background-color: #2E55A5;
    }
  }

  .footer-margin {
    margin-bottom: 120px;
  }

  .login-btn, .register-btn {
    @media(max-width:991px) {
      width: 75% !important;
    }
  }

  .bg-img {
   background-image: url("/assets/RefugeeCompass_bg_rotated_opacity.png");
   background-position: 120% 100%;
   background-size: 850px 850px;
   background-repeat: no-repeat;
   background-color: #fff;
   @media(max-width:991px) {
    background-size: 500px 500px;
  }

  @media(max-width:450px) {
    background-position: top -10px right -80px;
    background-size: 350px 350px;
  }
}

body[dir="rtl"] {
  .checkbox{
    label:after {
      right: -13px;
    }
  }

  .bg-img {
    background-position: top left -50px;

    @media(max-width:991px) {
      background-position: top -25px left -60px;
    }
  }

  .show-pwd {
    right: auto !important;
    left: 0 !important;
  }
}

.pw-req {
  font-size: 12px;
}
</style>
